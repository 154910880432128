import { feature } from 'topojson-client';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';

// Step 1: Set up the base map
const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
  ],
  view: new View({
    center: [0, 0],
    zoom: 2,
  }),
});

// Step 2: Define function to load TopoJSON and create vector layer
async function get_new_layer() {
  try {
    console.log("Fetching TopoJSON data...");
    const response = await fetch('/data/ABS_2021_Census_Indigenous.topojson');
    
    if (!response.ok) {
      throw new Error(`Failed to fetch TopoJSON: ${response.status} ${response.statusText}`);
    }
    
    const topoData = await response.json();
    console.log("TopoJSON data fetched successfully:", topoData);

    // Convert TopoJSON to GeoJSON
    const geoJsonData = feature(topoData, topoData.objects.ABS_2021_Census_Indigenous);
    console.log("Converted TopoJSON to GeoJSON:", geoJsonData);

    // Read features from GeoJSON
    const features = new GeoJSON().readFeatures(geoJsonData, {
      dataProjection: 'EPSG:4326', // Assuming data is in WGS 84 (latitude/longitude)
      featureProjection: 'EPSG:3857', // Project to Web Mercator for the map
    });
    console.log("Features read from GeoJSON:", features.length);

    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    return vectorLayer;
  } catch (error) {
    console.error("Error in get_new_layer:", error);
  }
}

// Step 3: Function to apply color based on feature properties
function change_colors(features) {
  console.log("Applying colors to features...");
  features.forEach((feature) => {
    let r = 0, g = 0, b = 100;
    const values = feature.values_;

    console.log("Feature values:", values);

    if (values["Tot_Indigenous_P"] === undefined) {
      g = 255;
      b = 0;
    } else {
      const newVal = values["Tot_Indigenous_P"] / (values["Tot_Tot_P"] - values["Tot_Indig_status_ns_P"]);
      const lightB = { r: 241, g: 180, b: 144 };
      const darkB = { r: 61, g: 12, b: 2 };
      r = (lightB.r - darkB.r) * (1 - newVal) + darkB.r;
      g = (lightB.g - darkB.g) * (1 - newVal) + darkB.g;
      b = (lightB.b - darkB.b) * (1 - newVal) + darkB.b;
    }

    const style = new Style({
      fill: new Fill({
        color: `rgba(${r}, ${g}, ${b}, .7)`,
      }),
    });

    console.log("Setting style for feature:", style);
    feature.setStyle(style);
  });
  console.log("Color application complete.");
}

// // Step 4: Load the map and apply colors to the vector layer
// async function load_map() {
//   const vectorLayer = await get_new_layer();
//   if (!vectorLayer) {
//     console.error("Failed to load vector layer.");
//     return;
//   }

//   console.log("Checking features directly after loading layer...");
//   const myFeatures = vectorLayer.getSource().getFeatures();
//   console.log("Features loaded immediately:", myFeatures);

//   if (myFeatures.length > 0) {
//     change_colors(myFeatures); // Apply colors immediately if features are loaded
    
//     // Fit the map view to the extent of the features
//     const extent = vectorLayer.getSource().getExtent();
//     console.log("Fitting map to feature extent:", extent);
//     map.getView().fit(extent, { padding: [50, 50, 50, 50] });
//     console.log("Map view adjusted to feature extent.");
//   } else {
//     console.warn("No features found. Event listener may be needed.");
//   }

//   // Adding the vector layer to the map
//   console.log("Adding vector layer to map...");
//   map.addLayer(vectorLayer);
// }

// // Initialize map with vector layer
// load_map();

async function load_map() {
  // Get the loading message element and display it initially
  const loadingMessage = document.getElementById("loading-message");
  loadingMessage.style.display = "block";

  try {
    const vectorLayer = await get_new_layer();
    if (!vectorLayer) {
      console.error("Failed to load vector layer.");
      loadingMessage.textContent = "Failed to load map data.";
      return;
    }

    const myFeatures = vectorLayer.getSource().getFeatures();

    if (myFeatures.length > 0) {
      change_colors(myFeatures); // Apply colors if features are loaded

      // Fit the map view to the extent of the features
      const extent = vectorLayer.getSource().getExtent();
      map.getView().fit(extent, { padding: [50, 50, 50, 50] });

      // Hide the loading message once map is fully loaded
      loadingMessage.style.display = "none";
    } else {
      console.warn("No features found. Event listener may be needed.");
    }

    // Add the vector layer to the map
    map.addLayer(vectorLayer);
  } catch (error) {
    console.error("Error loading map data:", error);
    loadingMessage.textContent = "Error loading map data.";
  }
}

load_map();

